.font-footer{
    font-family: 'Oxygen', sans-serif;
}
.mainLayoutPadding1 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 5px;
}
.mainLayoutDashboardPadding1 {
    padding-top: 0px;
    padding-bottom: 15px;
    padding-right: 23px;
    padding-left: 23px; 
}
.ant-layout {
    background: #f3f4f9;
}